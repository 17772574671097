/* eslint-disable react/no-array-index-key */
import React from 'react';
import { graphql } from 'gatsby';

import AllLayouts from '../layouts/allLayouts';
import { Hero } from '../components/hero/Hero';

type PageType = {
  wpProductPage: {
    title: string,
    acfHeader: any,
    acfComponents: {
      components?: []
    }
  }
  shopifyProduct: {
    title: string,
    priceRangeV2: {
      maxVariantPrice: {
        currencyCode: string,
        amount: number
      },
    },
    variants: {
      title: string,
      price: number
    }
  }
}

const Product = ({ data }: {data: PageType}) => {
  const { acfHeader, acfComponents } = data.wpProductPage;
  const layouts = acfComponents.components;

  return (
    <>
      <Hero hero={{ ...acfHeader.hero, product: data.shopifyProduct }} />
      { layouts?.map((layout : any, index : number) => <AllLayouts key={index} component={layout} />) }
    </>
  );
};

export const query = graphql`
  query($handle: String!) {
    wpProductPage(slug: { eq: $handle }) {
      acfHeader {
        hero {
          info
          accordion {
            title
            body
          }
          heading
          button {
            title
            target
            url
          }
          layout
          subHeading
          description
          image {
            altText
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 2600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          images {
            altText
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 2600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          video {
            mediaItemUrl
          }
          poster {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      title
      databaseId
      acfComponents {
        components {
          ... on WpProductPage_Acfcomponents_Components_Banner {
            fieldGroupName
            layout
            column1Obj {
              button {
                target
                title
                url
              }
              image {
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              title
            }
            column2Obj {
              button {
                target
                title
                url
              }
              image {
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              title
            }
            column3Obj {
              button {
                target
                title
                url
              }
              image {
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              title
            }
          }
          ... on WpProductPage_Acfcomponents_Components_Cta {
            fieldGroupName
            column1
            column2
            title
            button {
              title
              url
              target
            }
          }
          ... on WpProductPage_Acfcomponents_Components_Partners {
            fieldGroupName
            title
            partner {
              ... on WpProductPage_Acfcomponents_Components_Partners_Partner_Item {
                description
                fieldGroupName
                title
                image {
                  altText
                  sourceUrl
                }
              }
            }
          }
          ... on WpProductPage_Acfcomponents_Components_Text {
            fieldGroupName
            heading
            column1
            column2
          }
          ... on WpProductPage_Acfcomponents_Components_Image {
            fieldGroupName
            image {
              altText
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on WpProductPage_Acfcomponents_Components_TextImage {
            fieldGroupName
            position
            background
            heading
            body
            button {
              title
              url
            }
            image {
              altText
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on WpProductPage_Acfcomponents_Components_Faq {
            fieldGroupName
            section {
              heading
              name
              text
              type
              faq {
                question
                answer
              }
            }
          }
        }
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      id
      handle
      storefrontId
      title
      shopifyId
      variants {
        title
        price
        storefrontId
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
    }
  }
`;

export default Product;
