import React from 'react';

// ACF Components
import {
  Text, TextImage, Image, Partners, Cta, Banner, Faq, Reviews,
} from '../../components';

type LayoutType = {
  component: {
    fieldGroupName: string
  }
}
interface LayoutInterface {
  [name: string]: {}
}

const AllLayouts = ({ component }: LayoutType) => {
  const { fieldGroupName } = component;

  const Default = () => (
    <div>
      In AllLayouts the mapping of this component is missing:
      {fieldGroupName}
    </div>
  );

  const layouts: LayoutInterface = {
    Page_Acfcomponents_Components_Text: Text,
    Page_Acfcomponents_Components_TextImage: TextImage,
    Page_Acfcomponents_Components_Image: Image,
    Page_Acfcomponents_Components_Partners: Partners,
    Page_Acfcomponents_Components_Cta: Cta,
    Page_Acfcomponents_Components_Banner: Banner,
    Page_Acfcomponents_Components_Faq: Faq,
    Page_Acfcomponents_Components_Reviews: Reviews,

    // Product page
    ProductPage_Acfcomponents_Components_Text: Text,
    ProductPage_Acfcomponents_Components_TextImage: TextImage,
    ProductPage_Acfcomponents_Components_Image: Image,
    ProductPage_Acfcomponents_Components_Partners: Partners,
    ProductPage_Acfcomponents_Components_Cta: Cta,
    ProductPage_Acfcomponents_Components_Banner: Banner,
    ProductPage_Acfcomponents_Components_Faq: Faq,

    page_default: Default,
  };

  const ComponentTag = layouts[fieldGroupName] || layouts.page_default;

  return (
    // @ts-ignore
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ComponentTag {...component} />
  );
};

export default AllLayouts;
